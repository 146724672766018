import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { t } from 'ttag'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { AppRoot } from '../../models3/AppRoot'
import API from '../../models3/API'
import { LocaleSelector } from './LocaleSelector'
import { RootConsumer } from './RootContext'
import { Language, getByIso639dash1 } from '../utils/Languages'
import { Root } from '../../models3/Root'
import { SlttHelp } from '../utils/Buttons'
import { Project, ProjectMessage } from '../../models3/ProjectModels'
import _ from 'underscore'
import { HelpLink } from '../utils/Icons'
import { VideoCache } from '../../models3/VideoCache'
import { LogoutButton } from './Login'
import { ToggleThemeButton } from './ToggleThemeButton'
import { Theme } from '../utils/LocalStorage'
import { SlttHelp2 } from '../utils/SLTTHelp2'
import { ServiceStatus } from './ServiceStatus'
import { StorageLimitStatusCheck } from './StorageLimitStatus'
import './NavigationBar.css'

export const version = '2.57.4' // bump-client-version 2024-09-17T15:52:38.911Z

const log = require('debug')('sltt:NavigationBar') 

function unreadProjectMessageCount(rt: Root | null) {
    if (rt === null) return 0

    function unreadMessageCount(_message: ProjectMessage) {
        return (_message.creator !== rt!.username && !_message.viewed) ? 1 : 0
    }

    let count = 0
    let { messages } = rt.project
    for (let message of messages) {
        count += unreadMessageCount(message)
        for (let response of message.responses) {
            count += unreadMessageCount(response)
        }
    }

    return count
}

interface IMessageIcon {
    messageCount: number,
    onClick: () => void,
    selected: boolean,
}

const MessageIcon: FC<IMessageIcon> =
    observer(({ messageCount, onClick, selected }) => {
        return (
            <div onClick={onClick}>
                <i
                    className={classNames("fa-comment-dots", "fas", "fa-2x", "edit-project-settings-button", "icon-hover",
                        { 'fa-border': selected })}
                >
                </i>
                {messageCount === 0 ? '' : <span className="p-n-unread-messages-flag">{messageCount}</span>}
            </div>
        )
    })

interface INavigationBar {
    appRoot: AppRoot,
    selectPage: (page: string) => void,
    selected: string,
    openTour: (() => void) | null,
    selectProject: (project: Project) => void,
}

const uiLanguageCodes = ['en', 'es', 'fi', 'fr', 'it', 'pt', 'sk', 'uk', 'vi'] // These are iso 691-1 codes.

export const uiLanguages: Language[] = uiLanguageCodes
    .map(code => ({ code, fullName: getByIso639dash1(code).local }))
    .sort((a, b) => a.fullName.localeCompare(b.fullName))

const LaunchDarklyHelloWorld: FC = () => {
    const flags = useFlags()
    if (!flags?.helloWorld) {
        return <></>
    }
    return (<i
        className="fa fa-2x fas fa-earth-americas"
        title="Hello World: Launch Darkly is working!"
        style={{marginLeft: '10px'}}
    />)
}

const NavigationBar: FC<INavigationBar> = (
    { selectPage, selected, appRoot, openTour, selectProject }
) => {
    
    // console.log('NavigationBar', { selected })
    let { iAmRoot, iAmDeveloper, id_token, username, useMobileLayout, useNarrowWidthLayout, rts, rt } = appRoot

    // let isProductionUrl = process.env.PUBLIC_URL === 'https://sltt-bible.net'

    let hasPortions = (rt: Root | null) => (rt?.project.portions.length ?? 0) > 0

    let projects = rts.map(rt => rt.project)

    let messageCount = unreadProjectMessageCount(rt)
    const rewindToKey = localStorage.acceptLocalThruKey
    const projectSelectorEnabled = rt ? !rt.project.videoBeingCompressed : true
    const altTitle = (rewindToKey || API.blockServerUpdates()) ? rt?.project?.dbAcceptor && (`${rt?.project?.dbAcceptor?.maxSeqSynced}/${rt?.project?.db.getRemoteSeq()}`) || '...' : undefined
    const appTitle = altTitle ?? (useNarrowWidthLayout ? t`SLTT` : /* translator: important */ t`Sign Language Translation Tool (${version})`)
    const basicTitleTooltip = `v${version}/${username}`
    const titleTooltip = altTitle && `${basicTitleTooltip} - API.blockServerUpdates() true` || basicTitleTooltip
    return (
        <RootConsumer>
            {rt => (
                <nav className="navbar navbar-default app-navbar-bottom-margin">
                    <div className='navbar-left'>
                        <div className="app-service-status">
                            <ServiceStatus />
                        </div>
                        <div className="navbar-header-title navbar-header navbar-brand navbar-padding navigation-bar-content"
                            style={altTitle ? { color: 'var(--color-warning)'} : undefined}
                            data-toggle="tooltip"
                            title={titleTooltip}>
                            {appTitle}
                            {rt && <UploadCount />}
                        </div>
                        <div className="app-storage-limit-status">
                            <StorageLimitStatusCheck />
                        </div>
                        {rt && <ProjectSelector {... {projects, project: rt.project, selectProject, enabled: projectSelectorEnabled}} />}
                    </div>
                    {id_token &&
                        <div className="navbar-header-icons navbar-header">
                            {!useMobileLayout && (
                                <>
                                    <LaunchDarklyHelloWorld />
                                    <SlttHelp2 id="passages.html" 
                                            video="a2" 
                                            tooltip={/* translator: important */ t`Play/record video passages.`} 
                                            place="bottom" >
                                        <Link to={`/index.html`} className="app-selector-icon video-camera-button">
                                            <i
                                                className={classNames("fa-video", "fa", "fa-2x", "edit-passages-button", "icon-hover",
                                                    {
                                                        'fa-border': selected === '/',
                                                        'app-selector-disabled': !hasPortions(rt),
                                                    })}
                                                onClick={() => { selectPage('/') }} >
                                            </i>
                                        </Link>
                                    </SlttHelp2>
                                    <SlttHelp id="status.html" tooltip={/* translator: important */ t`Edit project status.`} place="bottom" >
                                        <Link to={`/status`} className="app-selector-icon">
                                            <i
                                                className={classNames("fa-list-ol", "fa", "fa-2x", "edit-status-button", "icon-hover",
                                                    {
                                                        'fa-border': selected === '/status',
                                                        'app-selector-disabled': !hasPortions(rt),
                                                    })}
                                                onClick={() => { selectPage('/status') }} >
                                            </i>
                                        </Link>
                                    </SlttHelp>

                                    <SlttHelp id="portions.html" tooltip={/* translator: important */ t`Edit portions.`} place="bottom" >
                                        <Link to={`/portions`} className="app-selector-icon edit-portions-button">
                                            <i
                                                className={classNames("fa-chart-pie", "fas", "fa-2x", "icon-hover",
                                                    { 'fa-border': selected === '/portions' })}
                                                onClick={() => { selectPage('/portions') }} >
                                            </i>
                                        </Link>
                                    </SlttHelp>

                                    <SlttHelp id="settings.html" tooltip={/* translator: important */ t`Edit project settings.`} place="bottom" >
                                        <Link to={`/project-settings`} className="app-selector-icon">
                                            <i
                                                className={classNames("fa-cog", "fas", "fa-2x", "edit-project-settings-button", "icon-hover",
                                                    { 'fa-border': selected === '/project-settings' })}
                                                onClick={() => { selectPage('/project-settings') }} >
                                            </i>
                                        </Link>
                                    </SlttHelp>

                                    <SlttHelp id="messages.html" tooltip={/* translator: important */ t`SLTT and Project Messages.`} place="bottom" >
                                        <Link to={`/messages`} className="app-selector-icon">
                                            <MessageIcon
                                                messageCount={messageCount}
                                                selected={selected === '/messages'}
                                                onClick={() => { selectPage('/messages') }}
                                            />
                                        </Link>
                                    </SlttHelp>

                                    {/* <Link to={`${routePrefix}/`} className="app-selector-icon">
                                            <i
                                                className={classNames("fa-sign-out", "fa", "fa-2x")}
                                                onClick={() => { 
                                                    appRoot.logout()
                                                    history.replace(_path('/'))
                                                }}
                                                data-toggle="tooltip"
                                                title={t`Log out.`}>
                                            </i>
                                        </Link> */}

                                    {/* <Link to={`${routePrefix}/`} className="app-selector-icon">
                                            <i
                                                className={classNames("fa-bus", "fa", "fa-2x")}
                                                onClick={() => {
                                                    openTour && openTour()
                                                }}
                                                data-toggle="tooltip"
                                                title={t`Start tour.`}>
                                            </i>
                                        </Link> */}

                                    {iAmRoot && !Root.screenCaptureInProgress() && <Link to={`/database`} className="app-selector-icon">
                                        <i
                                            className={classNames("fa-database", "fas", "fa-2x", "icon-hover",
                                                { 'fa-border': selected === '/database' })}
                                            onClick={() => { selectPage('/database') }}
                                            data-toggle="tooltip"
                                            title={t`Database Records.`}>
                                        </i>
                                    </Link>}

                                    {iAmRoot && !Root.screenCaptureInProgress() && <Link to={`/projects`} className="app-selector-icon">
                                        <i
                                            className={classNames("fa-server", "fas", "fa-2x", "icon-hover",
                                                { 'fa-border': selected === '/projects' })}
                                            onClick={() => { selectPage('/projects') }}
                                            data-toggle="tooltip"
                                            title={t`Projects on server.`}>
                                        </i>
                                    </Link>}

                                    <HelpLink className='app-selector-icon fa-2x' tooltip={t`Get help`} />
                                </>
                            )}
                            <LocaleSelector
                                options={uiLanguages}
                                currentValue={rt ? rt.uiLanguage : 'en'}
                                chooseOption={option => rt ? rt.setLocale(option) : {}}
                            />
                            {Theme.hasThemeSetting() && <div className="app-toggle-theme">
                                <ToggleThemeButton />
                            </div>}
                            <div className="app-selector-icon2 fa-2x">
                                <LogoutButton {...{ appRoot }} />
                            </div>
                        </div>
                    }
                </nav>
            )}
        </RootConsumer>
    )
}

type ProjectNameProps = {
    name: string,
    displayName: string,
    description: string,
}

const ProjectName: FC<ProjectNameProps> = ({ name, displayName, description }) => {
    // Display up to the first 30 characters of the first line of
    // the description. If a period is reached before that, display
    // only up to the period.
    const MAX_DESCRIPTION_LENGTH = 30
    
    let element = document.createElement('div')
    element.innerHTML = description
    let firstChild = element.childNodes[0]
    let textContent = firstChild?.textContent
    let innerText = textContent?.slice(0, MAX_DESCRIPTION_LENGTH).trim() || ''
    let indexOfPeriod = innerText.indexOf('.')
    
    // '.' doesn't exist in description. Make it
    // larger than the max index we want so that it does
    // not affect our index calculation
    if (indexOfPeriod < 0) {
        indexOfPeriod = MAX_DESCRIPTION_LENGTH
    }
    
    let index = Math.min(indexOfPeriod, MAX_DESCRIPTION_LENGTH - 1)
    let displayedDescription = innerText.slice(0, index + 1).trim()
    if (innerText.length > displayedDescription.length) {
        displayedDescription = displayedDescription.concat('...')
    }
    let displayedOption = displayName
    if (displayedDescription !== '') {
        displayedOption = `${displayName} / ${displayedDescription}`
    }
    element.remove()

    return (
        <option key={name} value={name}>{displayedOption}</option>
    )
}


type ProjectSelectorProps = {
    project: Project,
    projects: Project[],
    selectProject: (project: Project) => void,
    enabled: boolean,
}

const ProjectSelector: FC<ProjectSelectorProps> = observer(({ project: currentProject, projects, selectProject, enabled }) => {
    function chooseOption(name: string) {
        let option = projects.find(v => v.name === name) || currentProject
        selectProject(option)
    }

    let { name } = currentProject
    projects = _.sortBy(projects, project => project.displayName || project.name)

    if (projects.length > 1) {
        return (
            <div className='project-selector-dropdown'>
                <div>
                    <select
                        className='custom-select'
                        value={name}
                        onChange={e => chooseOption(e.target.value)}
                        disabled={!enabled}
                    >
                        {projects.map(project =>
                            <ProjectName 
                                key={project.name}
                                name={project.name} 
                                displayName={project.displayName} 
                                description={project.description} />)}
                    </select>
                </div>
            </div>
        )
    }

    return (
        <div className='project-selector-dropdown'>
            <div>
                <ProjectName 
                    name={currentProject.name} 
                    displayName={currentProject.displayName} 
                    description={currentProject.description} />
            </div>
        </div>
    )
})

const UploadCount: FC = observer(() => {
    let uploadCount = VideoCache.videoCacheUploader.uploadCount
    if (uploadCount == 0) return null

    return (
        <div className="upload-count">
            <span
                className={`fas fa-cloud-upload-alt upload-count-icon`}
                data-toggle="tooltip"
                title={/* translator: important */ t`Number of video blocks waiting to upload.`} />
            <span className="upload-count-value">{uploadCount}</span>
        </div>
    )
})


export default observer(NavigationBar)
